<template>
  <button>
    <slot />
  </button>
</template>

<script setup></script>

<style scoped>
button {
  border: 1px solid #818181;
  background: transparent;
  cursor: pointer;
  padding: 10px 15px;
  font-family: "SamsungOne-900", Helvetica;
  border-radius: 10px;
}
</style>
